import cc from "classnames"

import { $auth } from "@stores/auth"
import { useStore } from "@nanostores/react"

import type { LinkProps } from "@components/Link"
import Container from "@components/Container"
import RecipeCard from "@components/RecipeCard"
import SpacingContainer from "@components/SpacingContainer"
import CmsLink from "@components/CmsLink"
import CmsButton from "@components/CmsButton"

interface RecipeCollectionModuleProps extends Domain.Shared.DataTransferObjects.BlockBaseConfigData {
  recipes: Domain.Recipe.DataTransferObjects.RecipeData[]
  headline: string
  link_props?: LinkProps
  background_color: Domain.Shared.Enums.ColorPaletteEnum
  cta: Domain.Shared.ValueObjects.Link[]
  should_hide_link_on_desktop?: boolean
}

function RecipeCollectionModule(props: RecipeCollectionModuleProps): JSX.Element {
  const { headline, recipes } = props
  const { margin_top, margin_bottom, padding_top, padding_bottom, background_color } = props
  const spacingMarginTop = margin_top?.value ?? "md"
  const spacingMarginBottom = margin_bottom?.value ?? "none"
  const spacingPaddingTop = padding_top?.value ?? "none"
  const spacingPaddingBottom = padding_bottom?.value ?? "none"

  const { user } = useStore($auth)
  const isHidden = (props.hide_for_logged_in_users && user) || (props.hide_for_logged_out_users && !user)

  if (isHidden) return <></>

  return (
    <SpacingContainer
      margin_top={spacingMarginTop}
      margin_bottom={spacingMarginBottom}
      padding_top={spacingPaddingTop}
      padding_bottom={spacingPaddingBottom}
      background_color={background_color}
    >
      <Container>
        <div className="mb-24 md:mb-32">
          <div className="flex items-center justify-between">
            <h2 className="pr-8 text-20 font-semibold leading-[120%] md:text-32 md:leading-48">{headline}</h2>
            {props.cta && props.cta.length ? (
              <CmsLink cta={props.cta}>
                <div
                  className={cc("ml-auto", {
                    "md:hidden": props.should_hide_link_on_desktop,
                  })}
                >
                  <CmsButton cta={props.cta} appearance="rounded" />
                </div>
              </CmsLink>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="grid w-full grid-cols-2 gap-x-16 gap-y-36 md:grid-cols-4" data-id="card-gallery-wrapper">
          {recipes.map((recipe, i) => {
            if (!recipe) return <div key={i}></div>
            return <RecipeCard recipe={recipe} key={i} is_above_fold={props.is_above_fold} />
          })}
        </div>
      </Container>
    </SpacingContainer>
  )
}

export default RecipeCollectionModule
